$border-green-color: #00A180;
$background-checkbox: #222222;

label{
    margin: 0px;
}
.stk003 {
  position: fixed;
  top: 0vh;
  bottom: 0px;
  right: -10%;
  left: -10%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.533);
  z-index: 1002;
}

.container_stk003 {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  left: 50%;
  border: 1px solid #ffffff;
  border-radius: 16px;
  background-color: #000;
  padding: 25px 16px;
  font-size: 10px;
}

@media screen and (min-width: 690px) {
  .container_stk003 {
    width: 60%;
  }
}

.container_stk003_content{
    padding-right: 30px;
    .stk003_address{
        margin-bottom: 12px;
        .address_title{
            color: #ffffff;
            font-size: 10px;
            margin-bottom: 7px;
        }
        .address{
            display: flex;
            justify-content: space-between;
            label{
                color: #ffffff;
                font-size: 10px;
            }
            button{
                font-size: 8px;
                border: 1px solid $border-green-color;
                width: 41px;
                height: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $background-checkbox;
                margin: 0px;
                padding: 0px;
                border-radius: 3px;
            }
        }
    }
    .stk003_network{
        margin-bottom: 20px;
        label{
            color: #ffffff;
            font-size: 10px;
        }
    }
}


.modal_caution{
    margin-top: 32px;
    border: 1px solid #ff3636;
    border-radius: 8px;
    padding: 16px 8px;
    background-color: #141414;
    .modal_caution_title{
      display: flex;
      align-items: center;
      font-size: 10px;
      label{
        margin: 0px 0px 0px 7px;
      }
      svg{
        width: 13px;
        height: 13px;
        fill: #c52d2d;
        background-color: #ffffff;
        border-radius: 50%;
      }
    }
  }
  .modal_caution_content{
    margin-left: 20px;
    font-size: 8px;
    color: #ffffff;
    margin-top: 4px;
    .label_margin{
        margin: 16px 0px;
    }
  }
  .btn_close{
    position: absolute;
    top: 15px;
    right: 16px;
    width: 21px;
    height: 21px;
    z-index: 1100000;
    cursor: pointer;
  }
  .deposit_amount{
    margin-bottom: 32px;

    .deposit_amount_minimum, .deposit_amount_maximum{
      display: flex;
      
    }
    label{
      font-size: 10px;
    }
    .deposit_amount_title{
      margin-right: 39px;
    }

  .deposit_amount{
    .deposit_amount_minimum, .deposit_amount_maximum{
      display: flex;
    }
    .deposit_amount_title{
      margin-left: 39px;
    }
    .label{
      font-size: 10px;
    }
  }

}
.notice_copy{
  position: absolute;
  left: 50%;
  top: -24px;
  transform: translate(-50%, -50%);
  background-color: #6c6c6c;
  color: #ffffff;
  z-index: 1002;
  font-size: 12px;
  width: 200px;
  border-radius: 10px;
  padding: 4px 0px;
  text-align: center;
  display: none;
}

@media screen and (max-width: 320px) {
  .container_stk003_content {
    .stk003_network {
      margin-bottom: 16px;
    }
    .stk003_address {
      margin-bottom: 8px;
    }
    .deposit_amount {
      margin-bottom: 16px;
    }
  }

  .modal_caution {
    margin-top: 16px;
  }

  .container_stk003 {
    padding: 16px;
  }
}