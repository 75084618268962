$border-green-color: #00A180;
$background-checkbox: #000000;


.stk004 {
  position: fixed;
  top: 0vh;
  bottom: 0px;
  right: -10%;
  left: -10%;
  height: 100%;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.533);
}

.container_stk004 {
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  left: 50%;
  border: 1px solid #ffffff;
  border-radius: 6px;
  background-color: #000;
  padding: 25px 19px;
  font-size: 10px;
}

@media screen and (min-width: 690px) {
  .container_stk004 {
    width: 60%;
  }
}

.stk004_main_content {

  label {
    margin-bottom: 8px;
  }

  .content_input_text {
    border: 1px solid $border-green-color;
    outline: none;
    background-color: $background-checkbox;
    border-radius: 6px;
    padding: 8px;
    color: #ffffff;
    font-size: 10px;
    line-height: 9px;
    width: 100%;
  }

  .content_radio {
    margin-top: 21px;
    display: flex;

    .content_radio_item {
      display: flex;
      justify-content: center;
      align-items: center;

      .label_text {
        margin: 2px 18px 0 3px;
        cursor: pointer;
      }
    }

    .content_label_input {
      text-align: center;
      height: 2em;
      width: 2em;
      float: left;
      background-color: $background-checkbox;
      border: 1px solid $border-green-color;
      transition: 200ms;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      cursor: pointer;

      svg {
        color: #ffffff;
        fill: #ffffff;
        opacity: 0;
        width: 9px;
        height: 8px;
      }
    }

    input[type=radio] {
      border: 0;
      clip: rect(0, 0, 0, 0);
      height: 1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      white-space: nowrap;
      width: 1px;
    }

    input[type=radio]:checked+.content_label_input {
      background: $border-green-color;

      svg {
        opacity: 1;
      }
    }
  }

  .content_select {
    margin-top: 13px;
    margin-bottom: 16px;

    .ant-picker {
      background-color: $background-checkbox;
      border: 1px solid $border-green-color;
      border-radius: 6px;
      width: 104px;
      height: 25px;

      .ant-picker-input {}
    }
  }

  .content_transactionId {}

  .content_btn {
    margin: 39px 0px 0px 0px;
    display: flex;
    justify-content: space-around;

    button {
      width: 145px;
      height: 31px;
      color: #ffffff;
      border: 1px solid $border-green-color;
      font-size: 14px;
      border-radius: 6px;
      margin: 0px;
    }

    .btn_deposit_report {
      background-color: $border-green-color;
    }

    .btn_cancel {
      background-color: #7e807f;
      border-color: #7e807f;
    }
  }
}

input[type="date"] {
  background-color: $background-checkbox;
  border: 1px solid $border-green-color;
  border-radius: 6px;
  color: #ffffff;
  font-size: 10px;
  padding: 8px;
  height: 25px;
  outline: none;
  margin-right: 8px;

}

.time_safari::before {
  content: 'HH:mm';
  min-width: 15w;
}

input[type="date" i]::-webkit-calendar-picker-indicator {
  width: 8px;
  height: 5px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjQiIHZpZXdCb3g9IjE1MjQ0IDE2MTMgNSA0Ij48cGF0aCBkPSJtMTUyNDYuNSAxNjE3LTIuNS00aDV6IiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGRhdGEtbmFtZT0i5aSa6KeS5b2iIDEiLz48L3N2Zz4=");
}

input[type="time"] {
  background-color: $background-checkbox;
  border: 1px solid $border-green-color;
  border-radius: 6px;
  color: #ffffff;
  font-size: 10px;
  padding: 8px;
  height: 25px;
  outline: none;
}

input[type="time" i]::-webkit-calendar-picker-indicator {
  width: 8px;
  height: 5px;
  cursor: pointer;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjQiIHZpZXdCb3g9IjE1MjQ0IDE2MTMgNSA0Ij48cGF0aCBkPSJtMTUyNDYuNSAxNjE3LTIuNS00aDV6IiBmaWxsPSIjZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGRhdGEtbmFtZT0i5aSa6KeS5b2iIDEiLz48L3N2Zz4=");
}

@media screen and (max-width: 303px) {
  input[type="time"] {
    margin-top: 8px;
  }
}

@media screen and (max-width: 320px) {
  .container_stk004 {
    padding: 16px;

    .content_btn {
      margin-top: 24px;
    }
  }
}

.container_stk004_report {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 0px;

  .container_stk004_report_title {
    font-size: 12px;
    color: #ffffff;
    margin: 0px;
  }

  .container_stk004_report_btn {
    background-color: #00A180;
    border-radius: 6px;
    width: 145px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px 0px 0px 0px;
  }
}

.text_err {
  margin-top: 8px;
  color: #ff3636 !important;
  font-size: 8px;
}