$border-green-color: #00A180;

h1 {
  font-weight: 700;
}

.stk002-container-body {
  margin-top: 50px !important;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

.button-group {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  min-width: 100vw;
  left: 0;
  top: 56px;
  position: fixed;
  background-color: #333333;
  z-index: 10;

  .col-name {
    height: 50px;
    width: calc(50% - 1px);
    border-top: 2px solid #989898;
    border-bottom: 8px solid #989898;
    font-size: 16px;
    font-weight: bold;

    cursor: pointer;

    &.active {
      border-bottom: 8px solid #009a7a;
    }
  }

  hr {
    background-color: #525252;
    z-index: 10;
    height: 49px;
    width: 2px;
    margin: 0;
    padding: 0;
  }
}

.d-flex {
  justify-content: center;
  align-items: center;
}

.content-main-stk002 {
  h1 {
    color: #de7e6c;
    font-size: 16px;
    padding-top: 42px;
    margin-bottom: 26px;
  }

  .table {
    width: 100%;
    color: #ffffff;
    font-size: 12px;

    th {
      border-top: unset;
      padding: 0px;
      padding-bottom: 18px;
      padding: 0px 0px 18px 0px;

    }

    p {
      margin: 4px;
    }

    .text_color_orange {
      margin-bottom: 25px;
      color: #F7D26C;
      font-size: 10px;
    }

    .table_tr_input {
      th {
        padding-bottom: 5px;
      }

      input {
        outline: none;
        font-size: 12px;
        color: #ffffff;
        background-color: #141414;
        border: 1px solid $border-green-color;
        border-radius: 4px;
        padding: 2px 7px;
        min-width: 119px;
      }
    }
  }

  .content_btn_info_confirm {
    margin-bottom: 15px;

    button {
      margin: 0px;
      background-color: #222222;
      border: 1px solid $border-green-color;
      width: 100%;
      height: 48px;
    }
  }

  .modal_caution {
    margin-bottom: 23px;
    border: 1px solid #ff3636;
    border-radius: 8px;
    padding: 16px 8px;

    .modal_caution_title {
      display: flex;
      align-items: center;
      font-size: 10px;

      label {
        margin: 0px 0px 0px 7px;
      }

      svg {
        width: 13px;
        height: 13px;
        fill: #c52d2d;
        background-color: #ffffff;
        border-radius: 50%;
      }
    }
  }

  .modal_caution_content {
    font-size: 8px;
    color: #ffffff;
    margin-top: 4px;
    margin-left: 0px;

    ul {
      padding-left: 30px;
      margin: 0px;

      p {
        margin: 0px;
        font-size: 10px;
      }
    }
  }

  .content_btn_confirm {
    margin-bottom: 70px;

    button {
      height: 48px;
      width: 100%;
      margin: 0px;
      margin-bottom: 40px;
    }

    .btn_report {
      background-color: #7E807F;
      border: 1px solid #7E807F;
      color: #ffffff;
      cursor: not-allowed;
    }

    .enable_btn_report {
      background-color: #222222;
      border: 1px solid $border-green-color;
      color: #ffd96f;
    }
  }

  .table_input {
    width: 119px;
    height: 23px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 320px) {
  .table_input {
    margin-right: 4px;
  }
}
