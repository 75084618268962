.container__logout_modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.logout_modal {
  text-align: center;
  position: fixed;
  top: 40%;
  width: 90%;
  border: 1px solid #C8C8C8;
  padding: 40px 16px;
  border-radius: 7px;
  background-color: #000000;
  margin-top: 0!important;
  .logout_modal__title_text {
    font-size: 12px;
    color: #ffffff;
  }

  .logout_modal__btn {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 32px;

    button {
      font-size: 14px;
      color: #ffffff;
      border-radius: 7px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      overflow: hidden;
    }

    .cancel {
      background-color: #7E807F;
      margin: 0px 15px 0px 0px !important;
    }

    .logout {
      background-color: #00A180;
      margin: 0px;
    }
  }
}
