$border-green-color: #00A180;

.staken-amount-container-body {
  margin-top: 50px !important;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;

  .title {
    margin-bottom: 4px;
  }
}

.d-flex {
  justify-content: center;
  align-items: center;
}

.button-group {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  min-width: 100vw;
  left: 0;
  top: 56px;
  position: fixed;
  background-color: #333333;
  z-index: 10;

  .col-name {
    height: 50px;
    width: calc(50% - 1px);
    border-top: 2px solid #989898;
    border-bottom: 8px solid #989898;
    cursor: pointer;

    &.active {
      border-bottom: 8px solid #009a7a;
    }
  }

  hr {
    background-color: #525252;
    z-index: 10;
    height: 49px;
    width: 2px;
    margin: 0;
    padding: 0;
  }
}

.bottom-group {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  min-width: 100vw;
  left: 0;
  bottom: 68px;
  position: fixed;
  background-color: #141414;
  z-index: 10;
  height: 80px;

  .col-name {
    height: 80px;
    width: calc(50% - 1px);
    border-top: 2px solid #313131;
  }

  .button {
    max-width: 135px;
    margin: 0;
  }
}

.staken-amount-container {
  background-color: #141414;
  padding-bottom: 0px;
  padding-top: 40px;
  margin-bottom: 150px!important;
  // height: 120vh;

  .amount-waiting-withdrawal {
    margin-top: 25px;
  }

  .information__text {
    font-size: 12px;
  }

  .home__input {
    background-color: #141414;
    border: 1px solid $border-green-color;
    width: 100%;
    border-radius: 10px;
    height: 121px;
    color: white;
    padding: 9px 8px;
    line-height: 16px;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  .information__user {
    margin-top: 40px;
    color: #ffffff;
    font-size: 12px;

    span {
      display: flex;

      .label_user_balance {
        margin-right: 32px;
      }
    }
  }

  .staking-information {
    position: relative;
  }

  .staken-information {
    margin-top: 32px;
    height: 100%;

    .table-staken-history {
      color: #ffffff;
      font-size: 12px;
      // min-height: 130vh;

      th {
        border: unset;
        padding: 9px 0;
        min-width: 30%;
        flex-wrap: nowrap;
      }

      td {
        border-top: unset;
        padding: 9px 0;

        &.money-up {
          color: #ff3636;
        }

        &.money-down,
        &.money-g {
          color: $border-green-color;
        }
      }

      .history {
        justify-content: left;

        .content {
          margin-left: 14px;

          label {
            text-align: left;
            margin: 0;
          }
        }

        .date-time {
          font-size: 8px;
        }
      }
    }
  }

  .bonus-information {
    hr {
      background: #00A180;
      margin: 16px -8px;
    }

    .game-circle {
      label {
        margin-top: 0px;
      }

      .label-reward {
        font-size: 8px;
        margin-top: 8px;
        color: #ffd96f !important;
      }

      .coin_circle {
        border: unset;
      }
    }
  }

  .information_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto 0px;
    width: 100%;

    button {
      border: 1px solid $border-green-color;
      border-radius: 5px;
      margin: 0px 8px 0px 0px !important;
      height: 73px;
      width: calc(33.33% - 8px);
      background-color: #222222;
      color: #ffffff;

      img {
        color: #ffd96f;
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
      }

      p {
        font-size: 8px;
        margin: 0px !important;
      }
    }
  }
}

.modal_background {
  position: fixed;
  background-color: rgba(34, 34, 34, 0.7);
  top: -30%;
  left: 0px;
  bottom: -30%;
  right: 0px;
  z-index: 100000;
}

.modal_container {
  width: calc(100% - 40px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #7A7A7A;
  border-radius: 8px;
  background-color: #000000;
  padding: 24px 16px;
}

@media screen and (min-width: 690px) {
  .modal_container {
    width: 60%;
  }
}

.modal_pay {
  .modal_caution {
    border: 1px solid #ff3636;
    border-radius: 8px;
    padding: 16px 8px;
    margin-top: 0px;

    .modal_caution_title {
      display: flex;
      align-items: center;
      font-size: 10px;

      label {
        margin: 0px 0px 0px 7px;
      }

      svg {
        width: 13px;
        height: 13px;
        fill: #c52d2d;
        background-color: #ffffff;
        border-radius: 50%;
      }
    }
  }

  .modal_caution_content {
    font-size: 8px;
    color: #ffffff;
    margin-top: 4px;

    ul {
      padding-left: 14px;
      margin: 0px;

      p {
        margin: 0px;
      }
    }
  }

  .modal_checkbox_accept {
    margin-top: 7px;

    span {
      font-size: 10px;
    }
  }

  .modal_withdrawl_address {
    margin-top: 27px;

    label {
      font-size: 10px;
      margin-bottom: 7px;
    }

    input {
      background-color: #000000;
      outline: unset;
      border: 1px solid $border-green-color;
      border-radius: 8px;
      padding: 7px 8px;
      font-size: 10px;
      color: #ffffff;
      width: 100%;
    }
  }

  .modal_address {
    margin-top: 24px;

    label {
      color: #ffffff;
      font-size: 10px;
    }
  }

  .modal_button {
    display: flex;
    justify-content: space-around;

    button {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 145px;
      height: 31px;
      border-radius: 6px;
      margin: 24px 0px 0px 0px;
    }

    .btn_apply {
      margin-right: 14px;
      background-color: $border-green-color;
      border: 1px solid $border-green-color;
    }

    .btn_cancel {
      background-color: #7e807f;
      border: 1px solid #7e807f;
    }
  }
}

.confirm_modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 336px;
  height: 175px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #7A7A7A;
  border-radius: 8px;
  background-color: #000000;

  label {
    font-size: 12px;
    color: #ffffff
  }

  .btn_confirm {
    font-size: 14px;
    color: #ffffff;
    width: 145px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0px 0px 0px;
  }
}

@media screen and (max-width: 320px) {
  .modal_container {
    padding: 16px;

    .modal_caution_content {
      margin-left: 8px !important;
    }

    .modal_withdrawl_address {
      margin-top: 16px;
    }

    .modal_address {
      margin-top: 16px;
    }

    .btn_apply {
      margin-top: 16px !important;
    }

    .btn_cancel {
      margin-top: 16px !important;
    }
  }
}

.span_space {
  letter-spacing: 0rem !important;
}

@media screen and (min-width: 480px) {
  .staken-information {

    th,
    td {
      text-align: center;
    }
  }

  .amount_title {
    margin-left: 5vw;
  }
}

// tbody{
//   padding-bottom: 1000px;
// }

