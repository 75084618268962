@font-face {
  font-family: 'HiraginoSans';
  src: local('HiraginoSans'), url('assets/fonts/Hiragino-Sans-W3.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'HiraginoSans';
  src: local('HiraginoSans'), url('assets/fonts/Hiragino-Sans-W4.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HiraginoSans';
  src: local('HiraginoSans'), url('assets/fonts/Hiragino-Sans-W6.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'HiraginoSans';
  src: local('HiraginoSans'), url('assets/fonts/Hiragino-Sans-W7.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'HiraginoSans';
  src: local('HiraginoSans'), url('assets/fonts/Hiragino-Sans-W8.otf') format('opentype');
  font-weight: bolder;
  font-style: italic;
}

body {
  /* height: 100%; */
  margin: 0;
  font-family: -apple-system, 'HiraginoSans', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
