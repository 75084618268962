.authentication_email {
  max-width: 1140px;
  margin-top: 24px !important;
  background-color: #141414;
  color: #fff;
  text-align: center;
  padding: 0px 16px;
}

.authentication_email_title_text {
  font-size: 16px;
  color: #eabbaa;
}

.authentication_email_warning {
  display: flex;
  margin-top: 44px;

  .authentication_email_warning_icon {
    svg {
      color: #c52d2d;
      background-color: #fff;
      border-radius: 50%;
    }
  }

  .authentication_email_warning_text {
    text-align: left;
    margin-left: 7px;

    p {
      font-size: 12px;
      margin: 0px;
    }
  }
}

button {
  background-color: #00A180;
  color: white;
  border: unset;
  width: 100%;
  height: 48px;
  border-radius: 6px;
  font-size: 14px;
  margin-top: 32px;
  margin-bottom: 40px;
}

.authentication_email_notice {
  text-align: left;

  label {
    font-size: 12px;
    color: #fff;
  }
}

.btn_disable{
  background-color: gray;
}
