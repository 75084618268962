$border-green-color: #00A180;

.wallet-container-body {
  margin-top: 50px !important;
}

.d-flex {
  justify-content: center;
  align-items: center;
}

.button-group {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  min-width: 100vw;
  left: 0;
  top: 56px;
  position: fixed;
  background-color: #333333;
  z-index: 10;

  .col-name {
    height: 50px;
    width: calc(50% - 1px);
    border-top: 2px solid #525252;
    border-bottom: 8px solid #7d7d7d;
    font-weight: bold;
    cursor: pointer;
  }

  hr {
    background-color: #525252;
    z-index: 10;
    height: 49px;
    width: 2px;
    margin: 0;
    padding: 0;
  }
}

.wallet-container {
  background-color: #141414;
  padding-bottom: 0px;
  padding-top: 40px;
  height: 100vh;

  .information__text {
    font-size: 12px;
  }

  .home__input {
    background-color: #141414;
    border: 1px solid $border-green-color;
    width: 100%;
    border-radius: 10px;
    height: 121px;
    color: white;
    padding: 9px 8px;
    line-height: 16px;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  .information__user {
    margin-top: 40px;
    color: #ffffff;
    font-size: 12px;

    span {
      display: flex;

      .label_user_balance {
        margin-right: 32px;
      }
    }
  }

  .staking-information {
    position: relative;
  }

  .wallet-information {
    margin-top: 16px;
    border: 1px solid #00A180;
    border-radius: 10px;
    padding: 8px;

    .game-circle {
      justify-content: center;

      label {
        margin-top: 29px;
        font-size: 10px;
      }

      .label-profit {
        font-size: 10px;
        margin-top: 8px;
      }
    }
    .circle__bj_coin {
      border-color: #F6E7A3 !important;
    }

    .circle__rl_coin {
      border-color: #FA6767 !important;
    }

    .circle__bc_coin {
      border-color: #A7A7A7 !important;
    }

    .coin_circle {
      width: 63px;
      height: 63px;
      border: 5px solid;
      border-radius: 50%;
      margin-right: 23px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .wallet-type {
      display: block;
      width: 100%;
      margin-top: 0px;
      margin-left: 15px;
      font-size: 14px;
      margin-bottom: 22px;
      color: #ffd96f !important;
      text-align: left;
      font-weight: bold;
    }

    .row-title {
      position: absolute;
      bottom: 10px;

      label {
        font-size: 8px;
      }

      .label-profit {
        font-size: 8px;
        margin-top: 8px;
      }
    }
  }

  .bonus-information {
    margin-top: 39px;
    hr {
      background: #00A180;
      margin: 16px -8px;
    }
    .game-circle {
        label {
          margin-top: 0px;
        }

        .label-reward {
          font-size: 8px;
          margin-top: 8px;
          color: #ffd96f !important;
        }

        .coin_circle {
          border: unset;
        }
      }
  }

  .information_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto 0px;
    width: 100%;

    button {
      border: 1px solid $border-green-color;
      border-radius: 5px;
      margin: 0px 8px 0px 0px !important;
      height: 73px;
      width: calc(33.33% - 8px);
      background-color: #222222;
      color: #ffffff;

      img {
        color: #ffd96f;
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
      }

      p {
        font-size: 8px;
        margin: 0px !important;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .wallet-information {
    .col-name {
      padding: 0;
    }

    .coin_circle {
      width: 63px !important;
      height: 63px !important;
    }
    }
}
