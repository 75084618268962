#root {
  background-color: #141414;
  min-height: 100%;
  --form-control-color: rebeccapurple;
}

label {
  display: block;
  color: white !important;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.login-container {
  padding: 0;
}

.login-pg {
  padding-top: 24px;

  hr {
    background-color: #CBAA9F;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.61);
  }
}

.login-pg .app-title {
  text-align: center;
  color: #EABBAA !important;
  margin-bottom: 42px;
}

.login-pg .page-title {
  text-align: center;
  margin-bottom: 34px;
}

.container {
  margin-top: 56px !important;
  background-color: #141414;
  padding-left: 24px;
  padding-right: 24px;
}

.navbar {
  background-color: #222222;
  position: fixed;
  width: 100%;
  z-index: 1001;
}

.navbar-header {
  svg {
    margin-right: 8px;
  }
}

.navbar-footer {
  bottom: 0;
  justify-content: space-around;
  z-index: 1001;
}

.navbar-footer a {
  margin-right: 0;
}

.navbar-brand {
  color: #CBAA9F;
  margin-left: 8px;
  img{
    width: 33px;
    height: 17px;
  }
}


.form-control {
  border: 1px solid #00A180;
  background-color: #141414;
}

.ant-checkbox-inner {
  border: 1px solid #00A180 !important;
  background-color: #141414 !important;

}

.form-group .check-box .ant-checkbox input {
  border: 1px solid #00A180;
  background-color: #141414;
}

.ant-checkbox-wrapper {
  span:last-child {
    font-size: 12px;
  }
}

.form-group input:focus {
  border: 1px solid #00A180;
  background-color: #141414;
  color: white;
}

.form-group input {
  color: white;
}

.error-feedback {
  color: #ff3636;
}

.btn-login {
  margin-top: 50px;
  background-color: #00A180;
  color: white;
  border: unset;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-bottom: 0px;
}

.btn-login:hover {
  background-color: #00A180;
  color: white;
  border: unset;
}

.btn-login:focus {
  background-color: #00A180;
  color: white;
  border: unset;
}

.btn-login:active {
  background-color: #00A180;
  color: white;
  border: unset;
}

.btn-signUp {
  margin-top: 0px;
  background-color: #00A180;
  color: white;
  border: unset;
  width: 100%;
  height: 48px;
  border-radius: 8px;
}

.ant-drawer {
  margin-top: 55px;
}

.ant-drawer-content-wrapper {
  max-width: 50vw;
}

.ant-drawer-body {
  background-color: #222222;
  color: white;
  padding-left: 16px;
  padding-top: 40px;
}

.anticon-menu,
.anticon-close {
  color: #00A180;
  margin-right: 8px;
}

.navbar-brand svg {
  fill: #808080;
  width: 23px;
  height: 20px;
}

.navbar-brand p {
  font-size: 8px;
  margin-bottom: 0px;
  margin-top: 10px;
  color: #fff;
}

.footer-nav {
  p {
    color: #ffffff;
  }

  svg {
    fill: #ffffff;
  }
}

.svg_footer_active {
  p {
    color: #00A180;
  }

  svg {
    fill: #00A180;
  }
}

.header_right {
  display: flex;
  align-items: center;

  p {
    margin: 0px 21px 0px 0px !important;
    font-size: 10px;
  }

  img {
    width: 24px;
    height: 22px;
    color: #00a180;
    cursor: pointer;
  }
}

.border_input_error {
  border-color: #ff3636 !important;
}

.side-bar {
  display: block;
  margin-bottom: 47px;
  cursor: pointer;

  svg {
    fill: #ffffff;
    width: 22px;
    margin-right: 15px;
  }
}

.blur {
  opacity: 0.2;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.loading-container {
  height: 100vh;
  .loading {
    top: calc(50% - 56px);
  }
}

@media screen and (max-width: 360px) {
  .side-bar {
    font-size: 12px;
  }
}
