.button_group {
    .button_group_item {
        width: 100%;

        button {
            margin: 0px;
        }
    }

    .button_upload {
        input {
            display: none;
        }

        .info_file {
            color: #fff;
            font-size: 10px;
            margin-bottom: 8px;

            p {
                margin-bottom: 4px;
            }
        }
    }

    .title_upload_file {
        color: #fff;
        font-size: 14px;
        margin-bottom: 22px;
        text-align: left;
    }

    .button_download {
        button {
            margin-bottom: 30px;
        }
    }
}

.update-data-container {
    padding: 0;
    max-width: 100vw;
    margin-top: 56px;
    position: relative;

    .group-btn-update {
        margin: 0;
    }

    .table-update-data {
        padding: 16px;
        border: 1px solid #00A180;
        border-radius: 8px;
        margin-top: 16px;

        button {
            height: 36px;
            line-height: 36px;
            margin-bottom: 16px;
        }
    }
}

.modal_uploads {
    position: fixed;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: #141414a0;
    z-index: 1000;
}

.modal_upload_file {
    padding: 1%;
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 60vw;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .modal_upload_file_content {
        padding: 3vh 3vw;

        .title {
            h1 {
                color: black;
                font-size: 18px;
            }

            margin-bottom: 20px;
        }

        .main_content {
            cursor: pointer;
            border: 1px dashed grey;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: rgb(229, 227, 227);
            padding: 5vw 0px;
            margin-bottom: 30px;

            .main_content_icon {
                svg {
                    width: 70px;
                    height: 70px;
                    fill: rgb(152, 152, 152);
                }

                margin-bottom: 20px;
            }

            .main_content_text {
                text-align: center;
                font-size: 14px;
                color: rgb(152, 152, 152);
            }

            .errorApi {
                margin: 10px 0px 0px 0px;
                color: red;
            }
        }

        .main_content_button {
            display: flex;
            justify-content: space-between;

            button {
                width: 45% !important;
                font-size: 14px;
                font-weight: bold;
            }

            .btn_cancel {
                background-color: rgb(229, 227, 227);
                color: black;
            }
        }
    }
}

.modal_success {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #6c6c6c;
    border-radius: 8px;

    p {
        font-size: 14px;
        color: #fff;
        height: 25px;
        width: 200px;
        padding: 0px 20px;
        text-align: center;
        line-height: 25px;
        margin: 0px;
    }
}

.modal_mail_report {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    .modal_success_mail {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 8px;
        padding: 20px;
        background-color: black;
        border: 1px solid #fff;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        .modal_success_title{
            text-align: center;
            font-weight: bold;
            font-size: 16px;
            color: #fff;
        }
        .modal_success_mail_content {
            p {
                text-align: center;
                color: #fff;
                font-size: 14px;
            }
        }

        .button_group_modal {
            display: flex;
            justify-content: center;

            button {
                width: 8vw;
                height: 30px;
            }
        }
    }
}

.button-wrapper {
    margin-top: 10px;

    button {
        text-align: center;
        height: 30px;
        width: 100px;
    }

}

.file_infos {
    display: flex;

    .file_icon {
        width: 8vw;
        fill: #00A180;
        margin-right: 3vw;
    }

    .file_detail {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;

        .file_name {
            text-overflow: ellipsis;
            overflow: hidden;
            width: 20vw;
            height: 1.2em;
            white-space: nowrap;
        }
    }
}

.drag-area.active {
    border: 2px solid #fff;
}

.error {
    color: red;
}

@media only screen and (max-width: 769px) {
    .modal_upload_file {
        min-width: 85%;
    }

    .button_group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .button_group_item {
        width: 100% !important;
    }

    .button_upload {
        margin-bottom: 10vh;
        text-align: center;
    }

    .main_content_button {
        button {
            width: 50% !important;
        }
    }

}

@media only screen and (max-width: 555px) {
    .modal_upload_file {
        min-width: 85%;
    }

    .main_content_text {
        p {
            font-size: 10px;
        }
    }

    .file_detail {
        p {
            font-size: 10px;
            margin-bottom: 2px;
        }
    }

    .main_content_button {
        button {
            min-width: 60px !important;
            font-size: 12px !important;
            height: 30px;
            width: 30%;
        }
    }

    button {
        font-size: 12px;
    }

    .modal_success_mail {
        width: 80vw;
    }

    .button_group_modal {
        button {
            width: 20vw !important;
            ;
            height: 30px;
        }
    }
}