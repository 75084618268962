.register_container {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: auto !important;
    padding-top: 24px;

    .register__slogan {
        color: #eabbaa;
        font-size: 16px;
        margin-bottom: 42px;
    }

    .register__title {
        font-size: 16px;
        margin-bottom: 34px;
    }

    form {
        width: 100%;

        .form-register-input {
            margin-bottom: 24px;
        }

        .label_type {
            font-size: 14px;
            margin-bottom: 8px;
        }

        input {
            border-radius: 7px;
            height: 41px;
        }

        .form_input {
            border: 1px solid #00a180;
            background-color: #141414 !important;
            color: white !important;
            padding: 6px 12px;
            width: 100%;

            &:focus {
                outline: #00a180;
            }
        }

        .register__error {
            color: #ff3636;
            font-size: 8px;
            padding-top: 8px;
            margin: 0;

            &.policy {
                padding-top: 4px;
            }
        }

        .register__checkbox {
            display: flex;

            .register__checkbox_text {
                font-size: 12px;
                display: flex;
                align-items: center;
                margin-left: 10px;
            }

            .checkbox_link {
                a {
                    text-decoration: underline;
                    color: #00A180 !important;
                }
            }
        }

        .btn_submit {
            padding: 8px 4px;
            background-color: #00A180;
            color: #fff;
            border-radius: 7px;
            margin-top: 16px;
        }
    }
}

.border_input_error .ant-checkbox-inner {
    border: 1px solid #ff3636 !important;
}