.login-pg {
    .title {
        margin-bottom: 8px;
        font-size: 14px;
    }

    .form-input {
        margin-bottom: 32px;

        input {
            font-size: 14px;
            height: 41px;
        }
    }
}