body {
  background-color: #111111;
}

.board {
  position: absolute;
  top: 25%;
  left: 50%;
  height: 150px;
  width: 500px;
  margin: -75px 0 0 -250px;
  padding: 20px;
  font: 75px/75px Monoton, cursive;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 80px red, 0 0 30px FireBrick, 0 0 6px DarkRed;
  color: red;
}

#error {
  color: #fff;
  text-shadow: 0 0 80px #ffffff, 0 0 30px #008000, 0 0 6px #0000ff;
}

.btn-back{
  margin-top: 50px;
  border: 1px solid white;
  color: white;
  height: 5vmin;
  padding: 12px;
  text-decoration: none;
  border-radius: 5px;

  &:hover{
    background: white;
    color: #4D007D;
  }
}
