$border-green-color: #00A180;

.home__container {
  background-color: #141414;
  padding-bottom: 80px;
  padding-top: 40px;

  .information__text {
    font-size: 12px;
    margin-bottom: 16px;
  }

  .home__input {
    background-color: #141414;
    border: 1px solid $border-green-color;
    width: 100%;
    border-radius: 10px;
    height: 121px;
    color: white;
    padding: 9px 8px;
    line-height: 16px;
    resize: none;
    font-size: 12px;

    &:focus {
      outline: none;
    }
  }

  .information__user {
    margin-top: 40px;
    color: #ffffff;
    font-size: 12px;

    span {
      display: flex;

      .label_user_balance {
        margin-right: 32px;
      }
    }
  }

  .information__coin {
    margin-top: 25px;

    .circle__bj_coin {
      border-color: #F6E7A3 !important;
    }

    .circle__rl_coin {
      border-color: #FA6767 !important;
    }

    .circle__bc_coin {
      border-color: #A7A7A7 !important;
    }

    .coin_circle {
      width: 94px;
      height: 94px;
      border: 6px solid;
      border-radius: 50%;
      margin-right: 23px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #ffffff;
        font-size: 24px;

      }
    }

    label {
      display: block;
      width: 100%;
      margin-top: 36px;
      text-align: center;
      font-size: 12px;
    }
  }

  .information_btn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 80px auto 0px;
    width: 100%;

    button {
      border: 1px solid $border-green-color;
      border-radius: 5px;
      height: 73px;
      width: calc(33.33% - 8px);
      background-color: #222222;
      color: #ffffff;

      img {
        color: #ffd96f;
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
      }

      p {
        font-size: 8px;
        margin: 0px !important;
      }
    }
  }

  .col-name {
    padding: 0;
  }
}

@media screen and (max-width: 320px) {
  .information__coin {
    .coin_circle {
      width: 78px !important;
      height: 78px !important;
    }
  }
}
