$border-green-color: #00A180;

.affiliate-container-body {
  margin-top: 50px !important;
}

.d-flex {
  justify-content: center;
  align-items: center;
}

.button-group {
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  min-width: 100vw;
  left: 0;
  top: 56px;
  position: fixed;
  background-color: #333333;
  z-index: 10;

  .col-name {
    height: 50px;
    width: 100%;
    border-top: 2px solid #525252;
    font-weight: bold;
    cursor: pointer;
    color: #ffd96f;
  }

  .title_total_amount {
    border-bottom: 8px solid #00a180;
  }

  hr {
    background-color: #525252;
    z-index: 10;
    height: 49px;
    width: 2px;
    margin: 0;
    padding: 0;
  }
}

.affiliate-container {
  background-color: #141414;
  padding-bottom: 0px;
  padding-top: 40px;
  height: auto;
  margin-bottom: 88px;

  .affiliate-information {
    margin-top: 32px;
    padding: 8px;

    .label_reward {
      font-size: 14px;
      margin-top: 0px;
    }

    .btn-invite {
      margin: 0;
      background: #222222;
      border: 1px solid #00A180;

      p {
        margin: 0;
        color: #ffd96f;
        font-size: 12px;
      }
    }

    &.progress-information {
      margin-top: 16px;
    }

    .coin_circle {
      width: 63px;
      height: 63px;
      border: 5px solid;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .affiliate-type {
      display: block;
      width: 100%;
      margin-top: 0px;
      margin-left: 15px;
      font-size: 14px;
      margin-bottom: 22px;
      color: #ffd96f !important;
      text-align: left;
      font-weight: bold;
    }

    .row-title {
      position: absolute;
      bottom: 10px;

      label {
        font-size: 8px;
      }

      .label-profit {
        font-size: 8px;
        margin-top: 8px;
      }
    }

    .progress-group {
      text-align: center;

      .progress-info {
        label {
          margin-top: 10px;

          &.total-amount {
            font-size: 14px;
            color: #ffd96f !important;
          }

          &.next-reward {
            font-size: 10px;
            color: #a2a2a2 !important;
          }
        }

        img {
          width: 24px;
        }
      }

      .ant-progress {
        .ant-progress-circle-path {
          stroke: #00A180;
        }
      }

      .ant-progress-inner {
        width: 150px !important;
        height: 150px !important;
      }
    }

    .col-amount-group {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .amount-group {
        .amount-info {
          &.invited {
            margin-top: 24px;
          }

          label {
            margin-top: 8px;
            text-align: left;
          }
        }
      }
    }

    .ant-steps {
      .ant-steps-item-container {
        height: 70px;

        .steps_staking_bonus {
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;

          label {
            margin: 0px 0px 0px 4px;
            color: #ffd96f !important;
          }
        }

        .description_extra {
          font-weight: bold;
          color: #fff !important;
          font-size: 12px;
        }
      }

      .ant-steps-item {
        &.active {
          .ant-steps-item-tail {
            padding: 30px 0 0px !important;
            width: 6px !important;
            // background-color: #00A180;
          }

          .ant-steps-item-icon {
            background: #00A180;
            border-color: #00A180;

            span {
              color: #00A180;
            }
          }

          .ant-steps-item-description {
            color: #ffd96f !important;
          }
          .description_extra {
            font-weight: bold;
            color: #ffd96f !important;
            font-size: 12px;
          }
        }

        &.last-active {
          .ant-steps-item-tail {
            padding: 35px 0 3px !important;
          }
        }

        .ant-steps-item-icon {
          background: #000000;
          border: 3px solid #00A180;

          span {
            color: #000000;
          }
        }

        .ant-steps-item-tail {
          padding: 35px 0 3px;
          left: 13px !important;
          width: 6px !important;
        }

        .ant-steps-item-tail::after {
          width: 6px !important;
          background-color: #00A180;
        }

        .ant-steps-item-content {
          display: flex;
          margin-right: 24px;

          .ant-steps-item-title {
            min-width: 55%;
            font-size: 12px;
            color: #ffffff !important;
            padding: 0;
          }

          .ant-steps-item-description {
            line-height: 32px;
            color: #fff;
          }

          .steps_staking_bonus {
            font-size: 12px;
            padding: 0;
          }
        }
      }
    }

    &.invite_group {
      padding: 0;
      padding-top: 36px;
      position: relative;

      .notice_copy_invite {
        position: absolute;
        top: 0;
        font-size: 12px;
        color: #ffffff;
        background-color: #6c6c6c;
        border-radius: 16px;
        text-align: center;
        margin-bottom: 8px;
        width: 100%;
        display: none;

        p {
          line-height: 29px;
          margin-bottom: 0;
        }
      }
    }
  }


  .information_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto 0px;
    width: 100%;

    button {
      border: 1px solid $border-green-color;
      border-radius: 5px;
      margin: 0px 8px 0px 0px !important;
      height: 73px;
      width: calc(33.33% - 8px);
      background-color: #222222;
      color: #ffffff;

      img {
        color: #ffd96f;
        width: 20px;
        height: 20px;
        margin-bottom: 8px;
      }

      p {
        font-size: 8px;
        margin: 0px !important;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .affiliate-container {
    .affiliate-information {
      .ant-steps {
        .ant-steps-item {
          .ant-steps-item-content {
            div {
              font-size: 10px;
              color: #ffffff !important;
              padding: 0;
            }

            label {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}

.ant-progress-circle .ant-progress-inner {
  background-color: #222222;
}

.ant-progress-circle-trail {
  stroke: none;
}

@media screen and (min-width: 480px) {
  .ant-steps-vertical {
    margin-left: 10vw;
  }
}

